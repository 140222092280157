@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
/* Font weight mappings - ensure these are in CSS */
/* Monteserrat, Exo 2, Source Code Pro */
/* weight Google    Tailwind */
/* 100    thin         font-thin        *NOT Source Code Pro */
/* 200    extra-light  font-extralight */
/* 300    light        font-light */
/* 400    regular      font-normal */
/* 500    medium       font-medium */
/* 600    semi-bold    font-semibold */
/* 700    bold         font-bold */
/* 800    extra-bold   font-extrabold   *NOT Source Code Pro */
/* 900    black        font-black */
  body {
    @apply bg-white;
  }
  h1,h2,h3,h4 {
    @apply text-gray-900 font-sans font-medium;
  }
  label, legend, th, figcaption {
    @apply text-lg font-normal;
  }
  figcaption {
    @apply italic;
  }
  p,li,td,th,label,legend, figcaption {
    @apply text-gray-900 font-mono;
  }
  a {
    @apply text-red-600 underline;
  }
  p,li {
    @apply text-lg lg:text-xl font-light;
  }
  td, th {
    @apply text-lg;
  }
  h1 {
    @apply text-4xl lg:text-5xl leading-normal tracking-wider py-4 md:py-6;
  }
  h2 {
    @apply text-3xl lg:text-4xl leading-normal tracking-wide py-2 md:py-4;
  }
  h3 {
    @apply text-2xl lg:text-3xl leading-normal py-2;
  }
  h4 {
    @apply text-xl lg:text-2xl leading-normal py-2;
  }
  td {
    @apply font-light;
  }
  p {
    @apply leading-relaxed pb-4;
  }
  ul:not(.list-none), ol {
    @apply leading-relaxed list-inside pb-2 text-body-300;
  }
  ul:not(.list-none) li::before, ol li::before  {
    @apply text-heading-900 inline-block w-4 text-right p-0 mx-4;
  }
  ul:not(.list-none) li::before {
    @apply text-xs;
    content: "\25CF";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  }
  ol li {
    counter-increment: list-number;
  }
  ol li::before {
    content: counter(list-number)".";
  }
  table {
    @apply w-full table-auto mb-6;
  }
  th {
    @apply text-left;
  }
  td {
    @apply border-t border-b border-body-300;
  }
  blockquote {
    @apply leading-loose border-l-8 border-heading-900 my-4 p-4 bg-heading-100;
  }
  blockquote p {
    @apply italic text-xl pb-0 text-heading-900;
  }
  hr {
    @apply my-4;
  }

  button, input[type='submit'] {
    @apply border-0 text-white py-2 px-3 bg-primary-600 font-semibold tracking-wide rounded;
    &.secondary {
      @apply bg-secondary-600;
    }
    &.tertiary {
      @apply bg-tertiary-600
    }
  }

/* These types are set configured by the @tailwindcss/forms plugin */
/* input[type='text'], */
/* input[type='password'], */
/* input[type='email'], */
/* input[type='number'], */
/* input[type='url'], */
/* input[type='date'], */
/* input[type='datetime-local'], */
/* input[type='month'], */
/* input[type='week'], */
/* input[type='time'], */
/* input[type='search'], */
/* input[type='tel'], */
/* input[type='checkbox'], */
/* input[type='radio'], */
/* select, */
/* select[multiple], */
/* textarea */

  input:not([type='radio']):not([type='submit']), select, textarea {
    @apply placeholder-gray-300;
  }

  input:not([type='radio']):not([type='checkbox']):not([type='submit']), select, textarea {
    @apply w-full block;
  }

  input[type='checkbox'],input[type='radio'] {
    @apply w-5 h-5;
  }

  label, legend {
    abbr {
      text-decoration: none !important;
    }
  }

  pre code {
    @apply border-l-4 border-gray-400;
  }
}

@layer components {

}
@layer utilities {

}